import { Link } from "react-router-dom";
import styles from './Overview.module.css';

export default function Overview() {
  return (
    <div className={styles.overview}>
      <h1>Examples</h1>
      <ul>
        <li><Link to="/examples/planets">Planets</Link></li>
        <li><Link to="/examples/starfield">Starfield</Link></li>
      </ul>
    </div>
  );
}