import { useThree } from "@react-three/fiber"
import times from "lodash/times";
import { useEffect } from "react"
import { CubeTextureLoader } from "three"

const TEXTURE = '../../stars.jpg';

export default function Skybox() {
  const { scene } = useThree();

  useEffect(() => {
    const loader = new CubeTextureLoader();
    const texture = loader.load(times(6).map(() => TEXTURE));
    scene.background = texture;
  }, [scene]);

  return null;
}