import { Color, Vector3 } from "@react-three/fiber"
import { forwardRef } from "react";
import { Mesh } from "three";

export interface StarProps {
  color?: Color;
  position: Vector3;
  size?: number;
}

export default forwardRef<Mesh, StarProps>(
  function Star({
    color = "white",
    position,
    size = 1,
  }: StarProps, ref) {
    return (
      <mesh ref={ref} position={position}>
        <sphereGeometry args={[size, 32, 32]} />
        <meshBasicMaterial color={color} />
      </mesh>
    );
  },
);