import { Route, Routes } from 'react-router-dom';
import Overview from './Overview';
import './App.css';
import ExamplesLayout from './examples/ExamplesLayout';
import PlanetsExample from './examples/planets';
import StarFieldExample from './examples/starfield';

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="examples" element={<ExamplesLayout />}>
          <Route path="planets" element={<PlanetsExample />} />
          <Route path="starfield" element={<StarFieldExample />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
