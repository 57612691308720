import { useMergedRef } from '@mantine/hooks';
import { useFrame, Vector3 } from '@react-three/fiber';
import { forwardRef, Suspense, useRef } from 'react';
import { SphereGeometry, Texture } from 'three';

interface PlanetProps {
  position: Vector3;
  colorMap: Texture;
  bumpMap?: Texture;
  normalMap?: Texture;
  rotation?: number;
  size?: number
}

export default forwardRef<SphereGeometry, PlanetProps>(function Planet({
  position,
  colorMap,
  bumpMap,
  normalMap,
  rotation = 0,
  size = 1,
}: PlanetProps, forwardedRef) {
  const sphereRef = useRef<SphereGeometry>(null);
  const ref = useMergedRef(forwardedRef, sphereRef)

  useFrame(() => {
    if (rotation === 0) return;
    sphereRef.current?.rotateY(rotation);
  })

  return (
    <Suspense>
      <mesh position={position} receiveShadow castShadow>
        <sphereGeometry ref={ref} args={[size, 32, 32]} />
        <meshStandardMaterial map={colorMap} bumpMap={bumpMap} normalMap={normalMap} />
      </mesh>
    </Suspense>
  );
});