import { PerspectiveCamera } from '@react-three/drei';
import { useFrame, Vector3 } from '@react-three/fiber';
import { useRef } from 'react';
import { Mesh } from 'three';
import Star from './Star';

function randomVectors() {
  const results: Vector3[] = [];
  for (let z = -1000; z < 1000; z += 20) {
    const x = Math.random() * 1000 - 500;
    const y = Math.random() * 1000 - 500;
    results.push([x, y, z]);
  }
  return results;
 }

export default function Space() {
  const ref = useRef<Mesh[]>([]);

  const vectors = randomVectors();

  const speeds = vectors.map(v => (Math.random() + 0.5) * 3)

  useFrame(() => {
    ref.current.forEach((mesh, index) => {
      mesh.position.z += speeds[index];

      if (mesh.position.z > 200) {
        mesh.position.z = -1000;
      }
    })
  });

  return (
    <>
      <PerspectiveCamera makeDefault />

      {vectors.map((position, index) => (
        <Star
          key={index}
          ref={(r) => { r && (ref.current[index] = r) }}
          position={position}
        />
      ))}
    </>
  );
}