import { useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { CubeCamera, PCFSoftShadowMap, RGBFormat, WebGLCubeRenderTarget } from 'three';
import CameraControl from '../../debug/CameraControl';
import Planet from "./Planet";
import Skybox from './Skybox';
import Star from './Star';

export default function Space() {
  const { gl, scene } = useThree();

  const [planet1Color, planet1Bump, planet1Normal] = useTexture([
    '../../planet1.jpg',
    '../../planet1_bump.jpg',
    '../../planet1_normal.jpg',
  ]);

  const [planet2Color, planet2Bump, planet2Normal] = useTexture([
    '../../planet2.jpg',
    '../../planet2_bump.jpg',
    '../../planet2_normal.jpg',
  ]);

  const [planet3Color, planet3Bump, planet3Normal] = useTexture([
    '../../planet3.jpg',
    '../../planet3_bump.jpg',
    '../../planet3_normal.jpg',
  ]);

  useEffect(() => {
    gl.shadowMap.enabled = true;
    gl.shadowMap.type = PCFSoftShadowMap;
  }, [gl])

  useEffect(() => {
    const renderTarget = new WebGLCubeRenderTarget(256, { format: RGBFormat });
    const cubeCamera = new CubeCamera(1, 1000, renderTarget);
    cubeCamera.position.set(0, 0, 0);
    scene.add(cubeCamera);

    return () => {
      scene.remove(cubeCamera);
    }
  }, [scene]);

  return (
    <>
      <ambientLight intensity={0.01} />
      <Star color="#fc0" position={[0, 0, 20]} size={3} intensity={1} />
      <group position={[0, 0, 0]}>
        <Planet
          position={[0, 0, 0]}
          colorMap={planet1Color}
          bumpMap={planet1Bump}
          normalMap={planet1Normal}
          rotation={0.001}
        />
        <Planet
          position={[1, -1, -5]}
          colorMap={planet2Color}
          bumpMap={planet2Bump}
          normalMap={planet2Normal}
          rotation={0.00175}
        />
        <Planet
          position={[1.2, 3.5, -6]}
          colorMap={planet3Color}
          bumpMap={planet3Bump}
          normalMap={planet3Normal}
          rotation={-0.00075}
        />
      </group>
      <Skybox />
      <CameraControl />
      {/* <axesHelper args={[5]} />
      <gridHelper args={[100, 10]} /> */}
    </>
  );
}