import { Link, Outlet } from "react-router-dom";
import styles from './ExamplesLayout.module.css';

export default function ExamplesLayout() {
  return (
    <div className={styles.examples}>
      <div className={styles.back}>
        <Link to="/">Back to Overview</Link>
      </div>

      <div className={styles.example}>
        <Outlet />
      </div>
    </div>
  );
}