import { mergeRefs } from "@mantine/hooks";
import { useTexture } from "@react-three/drei";
import { Color, Vector3 } from "@react-three/fiber"
import { forwardRef, useEffect, useRef } from "react";
import { PointLight, SphereGeometry } from "three";
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare';

export interface StarProps {
  color?: Color;
  position: Vector3;
  size?: number;
  intensity?: number;
}

export default forwardRef<SphereGeometry, StarProps>(
  function Star({
    color = "white",
    intensity = 1,
    position,
    size = 1,
  }: StarProps, ref) {
    const lightRef = useRef<PointLight>(null);

    const colorMap = useTexture('../../sun.jpg');

    const [textureFlare0, textureFlare3] = useTexture([
      "../../lensflare0.png",
      "../../lensflare3.png",
    ]);

    const sphereRef = useRef<SphereGeometry>(null);

    useEffect(() => {
      const light = lightRef.current;
      if (!light) return () => {};

      light.shadow.mapSize.width = 2048;
      light.shadow.mapSize.height = 2048;

      const lensflare = new Lensflare();
      lensflare.addElement(new LensflareElement(textureFlare0, size * 700, 0, light.color));
      lensflare.addElement(new LensflareElement(textureFlare3, 60, 0.6));
      lensflare.addElement(new LensflareElement(textureFlare3, 70, 0.7));
      lensflare.addElement(new LensflareElement(textureFlare3, 120, 0.9));
      lensflare.addElement(new LensflareElement(textureFlare3, 70, 1));
      light.add(lensflare);

      return () => {
        light.remove(lensflare);
      }
    }, [size, textureFlare0, textureFlare3])

    return (
      <mesh position={position}>
        <pointLight
          ref={lightRef}
          color={color}
          intensity={intensity}
          castShadow
        />
        <sphereGeometry 
          ref={mergeRefs(ref, sphereRef)} 
          args={[size, 32, 32]} 
        />
        <meshStandardMaterial
          map={colorMap}
          emissive={color}
          emissiveIntensity={intensity}
          depthWrite={false}
        />
      </mesh>
    );
  },
);